

























import { Component, Vue } from 'vue-property-decorator'
import MediaContent from '@/components/MediaContent.vue'
import { MetaInfo } from 'vue-meta'

interface YoutubeChannel {
  title: string
  key: string
  playlistId: string
}

@Component({
  components: {
    MediaContent,
  },
  metaInfo(): MetaInfo {
    return {
      title: 'YouTube Kanal',
      meta: [
        {
          name: 'description',
          content:
            'In unseren Videos sprechen wir junge und angehende Führungskräfte an, es geht um Coaching, das Führungsverständnis, Leadership und das Arbeiten mit Teams, sowie um Erfolge und Ziele und natürlich darf auch Reflexion nicht fehlen.',
        },
      ],
    }
  },
})
export default class Media extends Vue {
  private channels: YoutubeChannel[] = [
    {
      title: 'Flo & Bence sprechen drüber',
      key: 'flo_bence',
      playlistId: 'PLS9EmUjyavYkVGNpAosbYcHa2gEMmf0nd',
    },
    {
      title: 'Flo spricht über...',
      key: 'flo',
      playlistId: 'PLS9EmUjyavYkuCwi2Xt4cqlbld6tnWML7',
    },
  ]
}
