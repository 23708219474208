




































import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'

@Component({
  components: {},
})
export default class Media extends Vue {
  @Prop({ required: true })
  private readonly title!: string
  @Prop({ required: true })
  private readonly playlistId!: string

  private videoId = 'LzyU500Cvtk'
  private videoItems: YoutubeItem[] = []
  private next = ''
  private prev = ''

  created(): void {
    this.fetchIds(undefined)
  }

  get videos() {
    return this.videoItems
      .map((video) => {
        const newDesc = video.snippet.description.split('\n')[0]
        return { ...video, snippet: { ...video.snippet, description: newDesc } }
      })
      .filter((video) => video.status.privacyStatus === 'public')
  }

  async fetchIds(pageToken: string | undefined): Promise<void> {
    const url = `https://youtube.googleapis.com/youtube/v3/playlistItems`

    const { data } = await axios.get<YoutubePlaylistItems>(url, {
      params: {
        part: 'snippet,status',
        playlistId: this.playlistId,
        // playlistId: 'UUOQyOPwVk3WlmP5IUh9myUA',
        key: 'AIzaSyBrXdW0hPYAfCVcvWskpzXW-8mpGmTPoCI',
        pageToken,
      },
    })
    this.videoItems = data.items
    this.next = data.nextPageToken
    this.prev = data.prevPageToken
  }

  playing(): void {
    console.log(' we are watching!!!')
  }
}
