var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{},[_c('h1',{staticClass:"h1"},[_vm._v("Media")]),_c('div',{staticClass:"flex"},_vm._l((_vm.channels),function(ref){
var title = ref.title;
var key = ref.key;
return _c('router-link',{key:("link-" + key),staticClass:"rounded bg-blue text-white hover:text-white hover:bg-blueDark space-x-12 m-8 px-12 py-8 w-full sm:w-256",attrs:{"to":("/media#" + key)}},[_vm._v(" "+_vm._s(title)+" ")])}),1),_vm._l((_vm.channels),function(ref){
var title = ref.title;
var playlistId = ref.playlistId;
var key = ref.key;
return _c('MediaContent',{key:("content-" + key),staticClass:"divide-y",attrs:{"title":title,"playlist-id":playlistId,"id":key}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }